<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col>
        <h4 class="mb-4">Representantes</h4>
        <b-card-text class="d-flex flex-column">
          <b-table
            class="lvj-table"
            :items="representatives"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
            @row-clicked="changeRoute"
          >
            <template #head(actions)>
              <b-button
                variant="success"
                size="sm"
                block
                class="mr-2"
                @click="newRepresentative"
              >
                NOVO
              </b-button>
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="changeRoute(row.item)"
              >
                <b-icon icon="pencil" />
              </b-button>
              <b-button
                :variant="row.item.ativo ? 'success' : 'danger'"
                size="sm"
                class="mr-2"
                @click="openRemoveRepresentativeModal(row.item)"
              >
                <b-icon icon="eye" />
              </b-button>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>

        <custom-modal
          v-model="showModal"
          :message="modalMessage"
          title="Tem certeza?"
          @confirm="deactivate"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomModal from "../../components/shared/CustomModal.vue";

export default {
  components: { CustomModal },
  name: "listRepresentantes",
  data() {
    return {
      page: 2,
      modalMessage: "",
      showModal: false,
      representanteToRemove: "",
      fields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "contato1",
          sortable: true,
        },
        {
          key: "contato2",
          sortable: true,
        },
        {
          key: "contato3",
          sortable: true,
        },
        {
          key: "contato4",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("representative", ["representatives", "isLoading"]),
  },
  methods: {
    ...mapActions("representative", [
      "getAllRepresentatives",
      "getMoreRepresentatives",
      "getOneRepresentative",
      "deactivateRepresentative",
    ]),
    ...mapMutations("representative", ["resetRepresentative"]),

    async getMore() {
      const { page } = this;
      await this.getMoreRepresentatives(page);
      this.page = page + 1;
    },
    newRepresentative() {
      this.resetRepresentative();
      this.$router.push("/representantes/criar");
    },
    async changeRoute({ id }) {
      await this.getOneRepresentative(id);
      this.$router.push(`representantes/${id}/editar`);
    },
    openRemoveRepresentativeModal(representante) {
      this.showModal = true;
      const { ativo, nome } = representante;

      if (ativo) {
        this.modalMessage = `Deseja deixar de exibir o representante(a): ${nome}`;
      } else {
        this.modalMessage = `Deseja reexibir o representante(a): ${nome}`;
      }

      this.representanteToRemove = representante;
    },
    async deactivate() {
      const { id, ativo } = this.representanteToRemove;
      const data = {
        id,
        values: {
          ativo: !ativo,
        },
      };
      await this.deactivateRepresentative(data);
      this.showModal = false;
    },
  },
  async created() {
    await this.getAllRepresentatives();
  },
};
</script>

<style lang="scss" scoped>
.lvj-search-input {
  width: 350px;
}
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
